import request from '@/request'
import store from '@/state/store'
import Vue from "vue";
const getSessionLookupData = (code)=>{
  if(sessionStorage.getItem("lookup")==undefined) return null
  let sessionData = JSON.parse(sessionStorage.getItem("lookup"))
  if(sessionData[code]!=undefined || sessionData[code]!=null){
    return sessionData[code]
  }else{
    return null
  }
}
const setSessionLookupData = (data)=>{
  let sessionData = JSON.parse(sessionStorage.getItem("lookup"))
  let newSessionData = Object.assign({}, sessionData, data)
  sessionStorage.setItem("lookup",JSON.stringify(newSessionData))
}
export const lookup = async (data) => {
    return request({
        url:'/api-gateway/service/lookup',
        method:'get',
        params:data,
    });
}
export const lookup2 = async (codeList) => {
  let codes = codeList.split(",")
  let remoteCodes = []
  let sessionResult = {}
  let result = {}
  for(let i=0;i<codes.length;i++){
    let lookupList = getSessionLookupData(codes[i])
    if(lookupList==null){
      remoteCodes.push(codes[i])
    }else{
      sessionResult[codes[i]] = lookupList
    }
  }
  if(remoteCodes.length>0){
    await lookup({"type":remoteCodes.join(",")}).then((res)=>{
      result = Object.assign({}, sessionResult, res.data)
      setSessionLookupData(res.data)
    })
  }else{
    result = sessionResult
  }
  return result;
}
export const getLookupItemText = (items,value)=>{
  if(items!=undefined && items.length>0){
      for(let i=0;i<items.length;i++){
          if(items[i].value==value){
            if(localStorage.getItem('language')!="zh-CN"){
              return items[i].labelEn
            }else{
                return items[i].label
            }
          }
      }
  }
  return value
}
export const trueText = (value)=>{
  if(value=="Y"){
    return "Yes"
  }else{
    return "No"
  }
}
export const getUUID = ()=> {
  if (typeof crypto === 'object') {
    if (typeof crypto.randomUUID === 'function') {
      return crypto.randomUUID();
    }
    if (typeof crypto.getRandomValues === 'function' && typeof Uint8Array === 'function') {
      const callback = (c) => {
        const num = Number(c);
        return (num ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (num / 4)))).toString(16);
      };
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, callback);
    }
  }
  let timestamp = new Date().getTime();
  let perforNow = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let random = Math.random() * 16;
    if (timestamp > 0) {
      random = (timestamp + random) % 16 | 0;
      timestamp = Math.floor(timestamp / 16);
    } else {
      random = (perforNow + random) % 16 | 0;
      perforNow = Math.floor(perforNow / 16);
    }
    return (c === 'x' ? random : (random & 0x3) | 0x8).toString(16);
  });
}
export const downFile = (url,fileName) => {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.download = fileName;
  a.href = url;
  document.body.appendChild(a);
  a.click(); 
  document.body.removeChild(a);
};
export const getLookupText = (sourceList,data) =>{
  let textList = [];
  let dataList = data;
  if(typeof data == 'undefined' || data=="") return []
  if(typeof data=="string"){
    dataList = data.split(",")
  }
  dataList.forEach(item => {
    sourceList.forEach(sourceItem =>{
      if(item==sourceItem.value){
        textList.push(sourceItem.label)
      }
    })
  });
  return textList
}
export const getUserText = (userList,data) =>{
  let text = "";
  if(typeof data == 'undefined' || data=="") return text
  userList.forEach(user =>{
    if(data==user.userName){
      text = user.staffName
    }
  })
  return text
}
export const disabledBeforeTodayDates=(date) =>{
  let today = Date.parse(new Date())
  let curDate = Date.parse(date)
  // 将两个日期都转换为毫秒格式，然后做差
  let diffDate = today - curDate // 取相差毫秒数的绝对值
  let totalDays = Math.floor(diffDate / (1000 * 3600 * 24)) // 向下取整
  if (totalDays>=0) {
    return true
  }
  return false
}
export const formatEmailInfo=(language,emailInfo,data,template)=>{
  let subject = template.subject||""
  let content = template.content||""
  if(language=="en-US"){
    subject = template.subjectEn||""
    content = template.contentEn||""
  }else if(language=="vn"){
    subject = template.subjectVn||""
    content = template.contentVn||""
  }
  for(let key in data){
    subject = subject.replaceAll("{"+key+"}",data[key])
    content = content.replaceAll("{"+key+"}",data[key])
  }
  emailInfo.subject = subject
  emailInfo.content = content
}
export const formatDate = (dateStr, fmt)=>{
  if(dateStr==undefined||dateStr==null|| dateStr=="") return ""
  //获取年份
  let date = new Date(dateStr)
  return dateToString(date,fmt)
}
function padLeftZero(str) {
  return ("00" + str).substr(str.length);
}
function dateToString(date,fmt){
  if (/(y+)/.test(fmt)) {
    // 把数字变成字符串
    let dateY = date.getFullYear() + "";
    //RegExp.$1 在判断中出现过，且是括号括起来的，所以 RegExp.$1 就是 "yyyy"
    fmt = fmt.replace(RegExp.$1, dateY.substr(4 - RegExp.$1.length));
  }

  //获取其他
  let o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
  };
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + "";
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? str : padLeftZero(str)
      );
    }
  }
  return fmt;
}
export const dateDiff = (dateStr, days)=>{
  if(dateStr==undefined||dateStr==null|| dateStr=="") return ""
  //获取年份
  let date = new Date(dateStr)
  date.setDate(date.getDate()+days)
  return dateToString(date,"yyyy-MM-dd")
}
export const iconClass =(filename)=>{
  if(filename.toUpperCase().indexOf(".DOC")!=-1){
      return ['mdi-file-document', 'text-primary'];
  }else if(filename.toUpperCase().indexOf(".XLS")!=-1){
      return ['mdi-microsoft-excel', 'text-warning'];
  }else if(filename.toUpperCase().indexOf(".PPT")!=-1){
      return ['mdi-microsoft-powerpoint', 'text-warning'];
  }else if(filename.toUpperCase().indexOf(".JPG")!=-1 ||filename.toUpperCase().indexOf(".PNG")!=-1){
      return ['mdi-image', 'text-success'];
  }else if(filename.toUpperCase().indexOf(".PDF")!=-1){
      return ['mdi-file-pdf', 'text-warning'];
  }else if(filename.toUpperCase().indexOf(".")==-1){
      return ['mdi-folder', 'text-warning'];
  }else if(filename.toUpperCase().indexOf(".MP4")!=-1 ||filename.toUpperCase().indexOf(".RM")!=-1){
      return ['mdi-play-circle-outline', 'text-danger'];
  }else if(filename.toUpperCase().indexOf(".ZIP")!=-1 ||filename.toUpperCase().indexOf(".RAR")!=-1 ||filename.toUpperCase().indexOf(".7Z")!=-1){
      return ['mdi-folder-zip', 'text-warning'];
  }else{
      return ['mdi-text-box', 'text-muted'];
  }
  
}
export const fileSize=(size)=>{
  if(size==null) return ""
  if(size>=1024*1024*1024){
      return (size*1.0/(1024*1024*1024)).toFixed(2)+"GB"
  }else if(size>=1024*1024){
      return (size*1.0/(1024*1024)).toFixed(2)+"MB"
  }else{
      return (size*1.0/1024).toFixed(2)+"KB"
  }
}
export const copyTextToClipboard =async (val) => {
  if (navigator.clipboard && navigator.permissions) {
    await navigator.clipboard.writeText(val)
  } else {
    const textArea = document.createElement('textArea')
    textArea.value = val
    textArea.style.width = 0
    textArea.style.position = 'fixed'
    textArea.style.left = '-999px'
    textArea.style.top = '10px'
    textArea.setAttribute('readonly', 'readonly')
    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand('copy')
    debugger
    document.body.removeChild(textArea)
    Vue.swal("复制成功");
  }
}
export const checkRole = (strRoles)=>{
  let result = false
  if(store.state.auth.currentUser){
    let roles = store.state.auth.currentUser.roles
    for(let i=0;i<roles.length;i++){
      if(strRoles.indexOf(roles[i])!=-1){
        result = true;
        break;
      }
    }
  }
  return result
}
export const checkUser = (userName)=>{
  if(store.state.auth.currentUser){
    return store.state.auth.currentUser.userName==userName
  }
  return false
}
export const isNumber = (num) =>{
	return /^[0-9]+.?[0-9]*$/.test(num);
}
export const formatLabelName = (item)=>{
  if(localStorage.getItem('language')!="zh-CN"){
    return item.labelEn
  }else{
    return item.labelCn
  }
}